import React, { useEffect, useState } from "react";
import ReleaseLandingPageLoader from "../../components/loaders/ReleaseLandingPageLoader";
import Track404 from "../../components/notFoundPages/trackNotFound";
import ArtistTemplate from "../../components/templates/ArtistTemplate";
import useGetArtist from "../../hooks/useGetArtist";

function ArtistHomePage(props) {
  const [artistName, setArtistName] = useState(null);
  const [artistId, setArtistId] = useState(null);

  useEffect(() => {
    let artist = props.params.artist;
    setArtistName(artist);
    // setArtistId("256m8qrr1OX3cxhHpnsNJe");
    setArtistId(artist);
  }, []);

  const artistData = useGetArtist(
    {
      artistId: artistId,
    },
    null,
    [artistName]
  );

  console.log("artistData:", artistData);

  if (!artistData) {
    return <ReleaseLandingPageLoader />;
  }

  if (artistData && artistData.error === "Artist not found") {
    return (
      <Track404
        song={{ artist: artistName, title: "" }}
        message="does not exist... to us. If you're a real person, we love you. Jesus loves you too!"
        hideReleaseInfo
      />
    );
  }

  if (artistData && !artistData.error) {
    return <ArtistTemplate artist={artistData} showGrain />;
  }
}

export default ArtistHomePage;
