import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import WaveFormAnimation from "./WaveformAnimation";

const MusicPlayer = ({ artist, audioUrl, song, sx }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const audioElement = new Audio(audioUrl);

    const handlePlayPause = () => {
      setIsPlaying(!isPlaying);
    };

    const handleTimeUpdate = () => {
      setCurrentTime(audioElement.currentTime);
    };

    const handleLoadedMetadata = () => {
      setDuration(audioElement.duration);
    };

    audioElement.addEventListener("timeupdate", handleTimeUpdate);
    audioElement.addEventListener("loadedmetadata", handleLoadedMetadata);

    if (isPlaying) {
      audioElement.play();
    } else {
      audioElement.pause();
    }

    return () => {
      audioElement.removeEventListener("timeupdate", handleTimeUpdate);
      audioElement.removeEventListener("loadedmetadata", handleLoadedMetadata);
    };
  }, [isPlaying, audioUrl]);

  const handleSeekChange = (_, newValue) => {
    setCurrentTime(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "min(560px, 80vw)",
        margin: "0 2rem",
        height: "42px",
        borderRadius: "56px",
        border: "1px solid var(--logoColor)",
        display: "flex",
        gap: "1rem",
        justifyContent: "center",
        alignItems: "center",
        display: "inline-flex",
        ...sx,
      }}
    >
      <IconButton onClick={() => setIsPlaying(!isPlaying)} disabled={!duration || !audioUrl}>
        {isPlaying ? (
          <PauseIcon sx={{ transform: { xs: "scale(1)", md: "scale(0.84)" }, color: "var(--textColor)" }} />
        ) : (
          <PlayArrowIcon sx={{ transform: { xs: "scale(1)", md: "scale(0.84)" }, color: "var(--textColor)" }} />
        )}
      </IconButton>

      <Typography
        sx={{
          color: "var(--textColor)",
          fontFamily: "Poppins",
          fontSize: 12,
          mx: "1rem",
          maxWidth: { xs: "70px", md: "140px" },
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {isPlaying ? `Now Playing - ${artist} - ${song}` : "Nothing playing"}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Slider
          disabled={!duration || !audioUrl}
          size="small"
          value={currentTime}
          max={duration}
          onChange={handleSeekChange}
          sx={{
            minWidth: { xs: "63px", md: "126px" },
            width: "100%",
            ml: "1.5rem",
            color: "var(--textColor)",
            ".MuiSlider-thumb": {
              borderRadius: "0",
              width: "4px",
            },
          }}
        />
      </Box>
      <Typography sx={{ color: "var(--textColor)", fontFamily: "Poppins", fontSize: 12, mr: "1rem" }}>
        {currentTime.toFixed(0)}:{duration > 0 ? Math.floor(duration).toFixed(0) : "00"}
      </Typography>
      <WaveFormAnimation playing={isPlaying} height={17} numBars={7} />
    </Box>
  );
};

export default MusicPlayer;
