import { Box, Link, Tab, Tabs, Typography } from "@mui/material";
import { Link as RouterLink } from "gatsby";
import React, { useEffect, useState } from "react";
import useGetNewestTrack from "../../../hooks/useGetNewestTrack";
import useWindowSize from "../../../hooks/useWindowSize";
import { lc, safeString, safeStringWithHyphens } from "../../_helpers";
import AlbumList, { AlbumListVariants } from "../ReleaseTemplate/AlbumList";
import MusicPlayer from "./MusicPlayer";

export function MusicSection(props) {
  const [featuredSong, setFeaturedSong] = useState(null);
  const [albums, setAlbums] = useState([]);
  const [eps, setEps] = useState([]);
  const [singles, setSingles] = useState([]);
  const [tabValue, setTabValue] = useState("albums");
  const { song, artistData } = props;

  const windowSize = useWindowSize();
  const newestRelease = useGetNewestTrack(
    {
      artistName: artistData.name,
    },
    null,
    [artistData.name]
  );
  console.log("newestRelease:", newestRelease, artistData);

  const linkStyles = {
    color: "var(--textColor)",
    fontSize: { xs: "1.8rem", md: "0.75rem" },
    fontFamily: "Poppins",
    fontStyle: "italic",
    fontWeight: 700,
    lineHeight: 1,
    wordWrap: "break-word",
  };

  const tabStyle = {
    color: "var(--logoColor)",
    fontSize: { xs: "2.8rem", md: "1.125rem" },
    fontFamily: "Hanson",
    fontWeight: 700,
  };

  useEffect(() => {
    async function getAlbums() {
      // Fetch albums from Spotify API
      const albums = [];
      setAlbums(albums);
    }

    async function getEps() {
      // Fetch EPs from Spotify API
      const eps = [];
      setEps(eps);
    }

    async function getSingles() {
      // Fetch singles from Spotify API
      const singles = [];
      setSingles(singles);
    }

    getAlbums();
    getEps();
    getSingles();
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (!song || !artistData || !newestRelease) {
    return "Loading...";
  }

  return (
    <Box
      className="music-section"
      sx={{
        width: `calc(${windowSize.width}px - (2.5rem * 2))`,
        p: "3.5rem",
        m: { xs: "25rem 2.5rem 0", md: "12.25rem 2.5rem 0" },
        backgroundColor: "#2D1B18",
        backgroundColor: "var(--color5)",
        borderRadius: "1.875rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: "10.5rem", md: 5 },
        zIndex: 1,
      }}
    >
      <MusicPlayer audioUrl={null} sx={null} artist={song.artist} song={null} artwork={null} />

      <Box
        sx={{
          color: "var(--textColor)",
          fontSize: { xs: 40, sm: 60 },
          fontFamily: "Hanson",
          fontWeight: 700,
          margin: "1.75rem 0 0.5rem",
        }}
      >
        MUSIC
      </Box>

      <Box
        className="music-left-right"
        sx={{
          width: "100%",
          minHeight: { xs: "105rem", md: "30.625rem" },
          display: { xs: "flex", sm: "flex" },
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: "10rem", md: 5 },
          position: "relative",
        }}
      >
        <Box
          className="music-left"
          sx={{
            width: { xs: "100%", sm: "35%" },
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "2.1875rem",
            paddingTop: "0.8125rem",
          }}
        >
          <Box className="featured-header" sx={{ display: "flex", alignItems: "center", gap: "1.5rem" }}>
            <Box
              sx={{
                color: "var(--logoColor)",
                fontSize: { xs: "3rem", sm: "1.5rem" },
                fontFamily: "Hanson",
                fontWeight: 700,
              }}
            >
              Featured
            </Box>

            <Box
              sx={{
                width: "5.6875rem",
                height: { xs: "2rem", md: "1.125rem" },
                marginTop: "0.1875rem",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "var(--logoColor)",
                  borderTopRightRadius: "0.875rem",
                  borderBottomRightRadius: "0.875rem",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "var(--color5)",
                  fontSize: { xs: "1.3rem", md: "0.875rem" },
                  fontWeight: 700,
                }}
              >
                NEW
              </Box>
            </Box>
          </Box>

          <Link
            display="flex"
            flexDirection="column"
            alignItems="center"
            position="relative"
            className="featured-song"
            to={
              "/" +
              safeString(lc(artistData.name)) +
              (newestRelease.trackData.album.total_tracks >= 7 ? "/albums/" : "/songs/") +
              safeStringWithHyphens(newestRelease.trackData.album.name)
            }
            component={RouterLink}
          >
            <Box
              className="Image12"
              sx={{
                width: { xs: "70vw", md: "17.0625rem" },
                height: { xs: "70vw", md: "17.0625rem" },
                backgroundImage: `url(${newestRelease.trackData.album.images[0].url})`,
                backgroundSize: "cover",
              }}
            />
            <Typography
              className="RescueMeRescue"
              variant="h6"
              component="div"
              sx={{
                position: "absolute",
                left: "116%",
                whiteSpace: "nowrap",
                width: "100%",
                overflow: "hidden",
                transform: "rotate(90deg)",
                transformOrigin: "0 0",
                color: "var(--textColor)",
                fontSize: { xs: "3rem", md: "1.3125rem" },
                fontFamily: "Poppins",
                fontWeight: 700,
                lineHeight: 1.5,
                wordWrap: "break-word",
              }}
            >
              {newestRelease.trackData.name}
            </Typography>
            <Box
              sx={{
                display: "flex",
                marginTop: { xs: "3.5rem", md: "1.425rem" },
                justifyContent: "flex-start",
                gap: { xs: "2.2rem", md: "0.875rem" },
              }}
              className="listen-on"
            >
              <Typography
                className="ListenOn"
                variant="body2"
                sx={{ ...linkStyles, fontWeight: 400, fontStyle: "normal" }}
              >
                Listen on
              </Typography>
              <Typography className="AppleMusic" variant="body2" sx={linkStyles}>
                Apple Music
              </Typography>
              <Typography className="Spotify" variant="body2" sx={linkStyles}>
                Spotify
              </Typography>
              <Typography className="Youtube" variant="body2" sx={linkStyles}>
                YouTube
              </Typography>
            </Box>
          </Link>
        </Box>

        <Box
          className="music-right"
          sx={{
            width: { xs: "100%", sm: "65%" },
            maxWidth: { xs: "100%", sm: "54vw" },
            height: "100%",
            p: "0",
            ".MuiButtonBase-root": {
              textTransform: "capitalize",
              padding: { xs: "3rem 3rem 5rem", md: "1.3125rem 1.75rem 2.625rem" },
              borderTopLeftRadius: "1rem",
              borderTopRightRadius: "1rem",
              "&.Mui-selected": {
                color: "var(--logoColor)",
                opacity: 1,
              },
            },
            ".MuiTabs-indicator": {
              backgroundColor: "var(--color1)",
              height: "3px",
            },
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            sx={{ mb: "2.3rem", borderBottom: "1px solid var(--color1)" }}
          >
            <Tab label="Albums" value="albums" sx={tabStyle} />
            <Tab label="EPs" value="eps" sx={tabStyle} />
            <Tab label="Singles" value="singles" sx={tabStyle} />
          </Tabs>
          <Box sx={{ height: { xs: "45rem", md: "21.875rem" }, position: "relative" }}>
            <AlbumList song={song} variant={AlbumListVariants.small} tab={tabValue} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
