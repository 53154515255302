import React from "react";

export function GallerySection() {
  return (
    <div
      className="GallerySection"
      style={{
        position: "relative",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: 55,
        display: "inline-flex",
      }}
    >
      <div
        className="Frame1"
        style={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: 10,
          display: "inline-flex",
        }}
      >
        <div
          className="Gallery"
          style={{
            color: "var(--textColor)",
            fontSize: 53.46,
            fontFamily: "Hanson",
            fontWeight: "700",
            wordWrap: "break-word",
          }}
        >
          GALLERY
        </div>
        <div
          className="InstagramLogoAndUsername"
          style={{
            width: 161.39,
            height: 52,
            left: 365.4,
            top: 0,
            position: "absolute",
          }}
        >
          <div
            className="Truevined"
            style={{
              left: 51.39,
              top: 12.12,
              position: "absolute",
              color: "var(--textColor)",
              fontSize: 20.05,
              fontFamily: "Poppins",
              fontWeight: "700",
              wordWrap: "break-word",
            }}
          >
            /truevined
          </div>
          <img
            className="Instagram"
            style={{
              width: 52,
              height: 52,
              left: 0,
              top: 0,
              position: "absolute",
            }}
            src="https://via.placeholder.com/52x52"
          />
        </div>
      </div>
      <div
        className="GridOrList"
        style={{
          width: 151.63,
          justifyContent: "space-between",
          alignItems: "center",
          gap: 10.02,
          display: "inline-flex",
        }}
      >
        <div
          className="Grid"
          style={{
            color: "var(--textColor)",
            fontSize: 16.71,
            fontFamily: "Poppins",
            fontWeight: "300",
            lineHeight: 17.54,
            letterSpacing: 2.09,
            wordWrap: "break-word",
          }}
        >
          GRID
        </div>
        <div
          className="GridIcon"
          style={{
            width: 15.03,
            height: 12.91,
            position: "relative",
          }}
        >
          <div
            className="Rectangle12"
            style={{
              width: 1.4,
              height: 1.21,
              left: 0,
              top: 0,
              position: "absolute",
              background: "#FECF3B",
            }}
          />
          <div
            className="Rectangle15"
            style={{
              width: 1.4,
              height: 1.21,
              left: 0,
              top: 5.85,
              position: "absolute",
              background: "#FECF3B",
            }}
          />
          <div
            className="Rectangle18"
            style={{
              width: 1.4,
              height: 1.21,
              left: 0,
              top: 11.7,
              position: "absolute",
              background: "#FECF3B",
            }}
          />
          <div
            className="Rectangle13"
            style={{
              width: 1.4,
              height: 1.21,
              left: 6.81,
              top: 0,
              position: "absolute",
              background: "#FECF3B",
            }}
          />
          <div
            className="Rectangle16"
            style={{
              width: 1.4,
              height: 1.21,
              left: 6.81,
              top: 5.85,
              position: "absolute",
              background: "#FECF3B",
            }}
          />
          <div
            className="Rectangle19"
            style={{
              width: 1.4,
              height: 1.21,
              left: 6.81,
              top: 11.7,
              position: "absolute",
              background: "#FECF3B",
            }}
          />
          <div
            className="Rectangle14"
            style={{
              width: 1.4,
              height: 1.21,
              left: 13.62,
              top: 0,
              position: "absolute",
              background: "#FECF3B",
            }}
          />
          <div
            className="Rectangle17"
            style={{
              width: 1.4,
              height: 1.21,
              left: 13.62,
              top: 5.85,
              position: "absolute",
              background: "#FECF3B",
            }}
          />
          <div
            className="Rectangle20"
            style={{
              width: 1.4,
              height: 1.21,
              left: 13.62,
              top: 11.7,
              position: "absolute",
              background: "#FECF3B",
            }}
          />
        </div>
        <div
          className="List"
          style={{
            color: "var(--textColor)",
            fontSize: 16.71,
            fontFamily: "Poppins",
            fontWeight: "300",
            lineHeight: 17.54,
            letterSpacing: 2.09,
            wordWrap: "break-word",
          }}
        >
          LIST
        </div>
      </div>
      <div
        className="ImageGallery"
        style={{
          width: 983.11,
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 29.23,
          display: "inline-flex",
        }}
      >
        <img
          className="Rectangle33"
          style={{
            width: 206.31,
            height: 497.82,
            borderRadius: 11.69,
          }}
          src="https://via.placeholder.com/206x498"
        />
        <img
          className="Rectangle34"
          style={{
            width: 206.31,
            height: 497.82,
            borderRadius: 11.69,
          }}
          src="https://via.placeholder.com/206x498"
        />
        <img
          className="Rectangle35"
          style={{
            width: 207.15,
            height: 497.82,
            borderRadius: 11.69,
          }}
          src="https://via.placeholder.com/207x498"
        />
        <img
          className="Rectangle36"
          style={{
            width: 206.31,
            height: 497.82,
            borderRadius: 11.69,
          }}
          src="https://via.placeholder.com/206x498"
        />
        <img
          className="Rectangle37"
          style={{
            width: 205.47,
            height: 497.82,
            borderRadius: 11.69,
          }}
          src="https://via.placeholder.com/205x498"
        />
      </div>
      <div
        className="SomeOfMyFavoritePicturesInspiredByGqCoversSunlightMasksFallColorsAndTankTopsACollectionOfAuthenticallyOriginalTruevinedMemorabliaNftSCouldBeInTheFuture"
        style={{
          width: 977.26,
          color: "var(--textColor)",
          fontSize: 17.54,
          fontFamily: "Poppins",
          fontWeight: "400",
          lineHeight: 29.23,
          wordWrap: "break-word",
        }}
      >
        Some of my favorite pictures inspired by GQ covers, sunlight, masks, fall colors and tank tops. A collection of
        authentically original Truevined memorablia. NFT’s could be in the future.
      </div>
    </div>
  );
}
