import { Box } from "@mui/material";
import React from "react";
import useSearchYouTube from "../../../hooks/useSearchYoutube";
import useWindowSize from "../../../hooks/useWindowSize";

export function VideoSection(props) {
  const { song, artistData } = props;

  const windowSize = useWindowSize();
  const youtubeData = useSearchYouTube(
    {
      artistName: song?.artist,
      // songName: track ? track.name : null,
      songName: "Always Fresh New",
    },
    null,
    [song?.artist, "Always Fresh New"]
  );
  console.log("youtubeData:", youtubeData);

  return (
    <Box
      className="VideosSection"
      sx={{
        position: "relative",
        width: `calc(${windowSize.width}px - (2.5rem * 2))`,
        p: "3.5rem",
        m: { xs: "25rem 2.5rem 0", md: "12.25rem 2.5rem 0" },
        borderRadius: "1.875rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: "10.5rem", md: 5 },
        zIndex: 1,
      }}
    >
      <div
        className="VideosHeader"
        style={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: 43,
          display: "inline-flex",
        }}
      >
        <div
          className="Videos"
          style={{
            color: "var(--textColor)",
            fontSize: 53.46,
            fontFamily: "Hanson",
            fontWeight: "700",
            wordWrap: "break-word",
          }}
        >
          VIDEOS
        </div>
        <div
          className="YoutubeLogoAndUsername"
          style={{
            width: 153.43,
            height: 42.6,
            position: "relative",
          }}
        >
          <div
            className="Truevined"
            style={{
              left: 43.43,
              top: 8.35,
              position: "absolute",
              color: "var(--textColor)",
              fontSize: 20.05,
              fontFamily: "Poppins",
              fontWeight: "700",
              wordWrap: "break-word",
            }}
          >
            /truevined
          </div>
          <img
            className="Youtube"
            style={{
              width: 43.43,
              height: 42.6,
              left: 0,
              top: 0,
              position: "absolute",
            }}
            src="https://via.placeholder.com/43x43"
          />
        </div>
      </div>
      <div
        className="LeftRight"
        style={{
          justifyContent: "flex-start",
          alignItems: "flex-end",
          gap: 43,
          display: "inline-flex",
        }}
      >
        <div
          className="FeaturedVideo"
          style={{
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-end",
            gap: 10,
            display: "inline-flex",
          }}
        >
          <img
            className="Image16"
            style={{
              width: 649,
              height: 361.2,
              borderRadius: 11.69,
            }}
            src="https://via.placeholder.com/649x361"
          />
          <div
            className="Customcursor"
            style={{
              left: 522.97,
              top: 134.48,
              position: "absolute",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 10,
              display: "inline-flex",
            }}
          >
            <div
              className="Ellipse8"
              style={{
                width: 46.13,
                height: 46.13,
                background: "#FECF3B",
                borderRadius: 9999,
              }}
            />
            <div
              className="Play"
              style={{
                width: 46.19,
                height: 8.33,
                left: 0.29,
                top: 19.24,
                position: "absolute",
                textAlign: "center",
                color: "black",
                fontSize: 12,
                fontFamily: "Hanson",
                fontWeight: "700",
                wordWrap: "break-word",
              }}
            >
              PLAY
            </div>
          </div>
          <div
            className="VideoMetaData"
            style={{
              width: 628.86,
              height: 13.64,
              paddingLeft: 9.19,
              paddingRight: 9.19,
              left: 8.45,
              top: 321.58,
              position: "absolute",
              borderRadius: 11.69,
              justifyContent: "space-between",
              alignItems: "center",
              gap: 286.5,
              display: "inline-flex",
            }}
          >
            <div
              className="TruevinedAlwaysFreshNew"
              style={{
                color: "var(--textColor)",
                fontSize: 16.71,
                fontFamily: "Poppins",
                fontWeight: "400",
                lineHeight: 17.54,
                wordWrap: "break-word",
              }}
            >
              Truevined - Always Fresh New
            </div>
            <div
              className="VideoViewsData"
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 10.02,
                display: "flex",
              }}
            >
              <img
                className="Eye"
                style={{
                  width: 15.87,
                  height: 15.87,
                }}
                src="https://via.placeholder.com/16x16"
              />
              <div
                className="256Views"
                style={{
                  color: "var(--textColor)",
                  fontSize: 16.71,
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: 17.54,
                  wordWrap: "break-word",
                }}
              >
                10,256 Views
              </div>
              <div
                className="Ellipse9"
                style={{
                  width: 4.18,
                  height: 4.18,
                  background: "var(--textColor)",
                  borderRadius: 9999,
                }}
              />
              <div
                className="DaysAgo"
                style={{
                  color: "var(--textColor)",
                  fontSize: 16.71,
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: 17.54,
                  wordWrap: "break-word",
                }}
              >
                3 Days ago
              </div>
            </div>
          </div>
        </div>
        <div
          className="DiscoverVideoGroup"
          style={{
            width: 299.86,
            height: 356.66,
            position: "relative",
          }}
        >
          <div
            className="VideoThumbnailGroup"
            style={{
              width: 299.86,
              height: 300.69,
              left: 0,
              top: 55.96,
              position: "absolute",
            }}
          >
            <img
              className="Rectangle59"
              style={{
                width: 141.99,
                height: 141.99,
                left: 157.03,
                top: -0,
                position: "absolute",
                background: "linear-gradient(0deg, #D9D9D9 0%, #D9D9D9 100%)",
                borderRadius: 17.54,
              }}
              src="https://via.placeholder.com/142x142"
            />
            <img
              className="Rectangle60"
              style={{
                width: 91.04,
                height: 87.7,
                left: 0,
                top: 152.85,
                position: "absolute",
                background: "linear-gradient(0deg, #D9D9D9 0%, #D9D9D9 100%)",
                borderRadius: 17.54,
              }}
              src="https://via.placeholder.com/91x88"
            />
            <img
              className="Rectangle63"
              style={{
                width: 50.95,
                height: 49.28,
                left: 0,
                top: 251.41,
                position: "absolute",
                background: "linear-gradient(0deg, #D9D9D9 0%, #D9D9D9 100%)",
                borderRadius: 17.54,
              }}
              src="https://via.placeholder.com/51x49"
            />
            <img
              className="Rectangle61"
              style={{
                width: 91.88,
                height: 87.7,
                left: 103.57,
                top: 152.85,
                position: "absolute",
                background: "linear-gradient(0deg, #D9D9D9 0%, #D9D9D9 100%)",
                borderRadius: 17.54,
              }}
              src="https://via.placeholder.com/92x88"
            />
            <img
              className="Rectangle64"
              style={{
                width: 50.12,
                height: 49.28,
                left: 62.64,
                top: 251.41,
                position: "absolute",
                background: "linear-gradient(0deg, #D9D9D9 0%, #D9D9D9 100%)",
                borderRadius: 17.54,
              }}
              src="https://via.placeholder.com/50x49"
            />
            <img
              className="Rectangle66"
              style={{
                width: 49.28,
                height: 49.28,
                left: 187.1,
                top: 251.41,
                position: "absolute",
                background: "linear-gradient(0deg, #D9D9D9 0%, #D9D9D9 100%)",
                borderRadius: 17.54,
              }}
              src="https://via.placeholder.com/49x49"
            />
            <img
              className="Rectangle62"
              style={{
                width: 91.04,
                height: 87.7,
                left: 207.98,
                top: 152.85,
                position: "absolute",
                background: "linear-gradient(0deg, #D9D9D9 0%, #D9D9D9 100%)",
                borderRadius: 17.54,
              }}
              src="https://via.placeholder.com/91x88"
            />
            <img
              className="Rectangle65"
              style={{
                width: 50.95,
                height: 49.28,
                left: 124.45,
                top: 251.41,
                position: "absolute",
                background: "linear-gradient(0deg, #D9D9D9 0%, #D9D9D9 100%)",
                borderRadius: 17.54,
              }}
              src="https://via.placeholder.com/51x49"
            />
            <img
              className="Rectangle67"
              style={{
                width: 51.79,
                height: 49.28,
                left: 248.07,
                top: 251.41,
                position: "absolute",
                background: "linear-gradient(0deg, #D9D9D9 0%, #D9D9D9 100%)",
                borderRadius: 17.54,
              }}
              src="https://via.placeholder.com/52x49"
            />
            <img
              className="Rectangle58"
              style={{
                width: 141.99,
                height: 141.99,
                left: 0,
                top: 0,
                position: "absolute",
                background: "linear-gradient(0deg, #D9D9D9 0%, #D9D9D9 100%)",
                borderRadius: 17.54,
              }}
              src="https://via.placeholder.com/142x142"
            />
          </div>
          <div
            className="DiscoverHeader"
            style={{
              width: 224.69,
              height: 24.22,
              left: 0,
              top: 0,
              position: "absolute",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 10.86,
              display: "inline-flex",
            }}
          >
            <div
              className="Discover"
              style={{
                width: 171.23,
                color: "var(--textColor)",
                fontSize: 26.73,
                fontFamily: "Monument Extended",
                fontWeight: "700",
                lineHeight: 17.54,
                letterSpacing: 1.25,
                wordWrap: "break-word",
              }}
            >
              Discover
            </div>
            <img
              className="ReplyArrow"
              style={{
                width: 24.22,
                height: 24.22,
                transform: "rotate(-90deg)",
                transformOrigin: "0 0",
              }}
              src="https://via.placeholder.com/24x24"
            />
          </div>
        </div>
      </div>
    </Box>
  );
}
