import { Box } from "@mui/material";
import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { isMobile } from "react-device-detect";
import defaultArtwork from "../../../../src/images/artwork4.jpg";
import useGetAlbums from "../../../hooks/useGetAlbums";
import useWindowSize from "../../../hooks/useWindowSize";
import clouds from "../../../images/clouds.png";
import { BREAKPOINT, TypeOfWindowUndefined, cap, removeHyphens } from "../../_helpers";
import Layout from "../../layout";
import { ArtistNav } from "./ArtistNav";
import { ContactSection } from "./ContactSection";
import { GallerySection } from "./GallerySection";
import { HeroSection } from "./HeroSection";
import { MusicSection } from "./MusicSection";
import { VideoSection } from "./VideoSection";

export const defaultArtistData = {
  artist: "Truevined",
  artistBio: "Singer, Rapper, Producer",
  featuredArtist: null,
  title: "Gold Soul",
  artwork: defaultArtwork,
  audioURL:
    "https://p.scdn.co/mp3-preview/6bb8a2b5cef0fa6d448b8503e4e170ea182c0157?cid=5225c4ca15f44b7aac57df853c6b5020",
  spotifyURL: "https://open.spotify.com/playlist/7vnuuglFyRoSq8rvteszqI",
  appleMusicURL: "https://geo.music.apple.com/ca/album/_/1623875173?i=1623875180",
  youtubeURL: "https://www.youtube.com/watch?v=p5uAOmsaFoM&ab_channel=Truevined",
  facebookPixel: "530282730968435",
  event: "",
};

function ArtistTemplate(props) {
  let artist = props.artist || "";
  let grain = props.showGrain || false;
  const artwork = artist?.artwork || null;

  const [spotifyExpanded, setSpotifyExpanded] = useState(false);
  const [imgExpanded, setImgExpanded] = useState(false);
  const [opacity, setOpacity] = useState(1);
  const [scale, setScale] = useState(1);
  const [logoScale, setLogoScale] = useState(1);
  const [version, setVersion] = useState("");
  const [song, setSong] = useState(null);
  const [artistName, setArtistName] = useState(null);
  const [artistId, setArtistId] = useState(null);
  const [releaseName, setReleaseName] = useState(null);
  const [track, setTrack] = useState(null);
  const windowSize = useWindowSize();
  const reallyIsMobile = isMobile || windowSize.width <= BREAKPOINT;

  const calcLogoScale = (scale) => {
    if (scale > 1.4) {
      return 1.4;
    }
    return scale;
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      setVersion(global.VERSION);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleScroll = () => {
        const scrollTop = window.pageYOffset;
        const scrollPercent = (scrollTop / (window.innerHeight - 100)) * 100;
        if (scrollPercent > 0) {
          setOpacity(1 - scrollPercent / 100);
          setScale(1 - scrollPercent / 175);
          setLogoScale(calcLogoScale(1 + scrollPercent / 175));
        }
      };

      if (reallyIsMobile) {
        window.addEventListener("scroll", handleScroll);
      } else {
        setScale(1);
        setOpacity(1);
      }

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [windowSize.width, windowSize.height, reallyIsMobile]);

  if (!artist) {
    console.log("no artist data sad face 🥺");
    return null;
  }

  const goToArtistHomepage = (artistUsername) => {
    if (artistUsername) {
      navigate(`${artistUsername}`);
    }
  };

  const trackData = useGetAlbums(
    {
      artistId: artistName,
    },
    null,
    [artistName]
  );

  useEffect(() => {
    let artist = getDataFromURL("artist");
    setArtistName(artist);

    let song = getDataFromURL("albumName");
    setReleaseName(removeHyphens(song));

    console.log("inside artistTemplate useEffect", artist, song);
  }, []);

  const getDataFromURL = (choice) => {
    console.log("inside getSongFromURL");
    if (TypeOfWindowUndefined()) {
      return null;
    }

    // Extract artistname and albumname from the URL in the browser
    const pathname = window.location.pathname;
    const parts = pathname.split("/").filter((part) => part !== ""); // Remove empty parts

    if (choice === "artist") {
      return parts[0];
    }
    if (choice === "albumName") {
      return parts[1];
    }

    return null;
  };

  useEffect(() => {
    if (trackData?.error) {
      console.log("error:", trackData.error);
      return;
    }

    if (trackData) {
      // all albums
      let song = {
        artist: artistName,
        artistUsername: artistName,
        artistBio: "Singer, Rapper, Producer",
        // featuredArtist: null,
        title: `Explore ${cap(artistName)}'s ${
          trackData.albums.filter((album) => album.album_group === "album").length
        } Albums`, // trackData.name,
        artwork: trackData?.albums[0]?.images[0].url, // trackData.images[0].url,
        audioURL: null, //trackData.tracks[0].preview_url,
        spotifyURL: "", //trackData.external_urls.spotify,
        spotifyArtistURL: trackData?.albums[0]?.artists[0]?.external_urls.spotify || "",
        // appleMusicURL: "https://geo.music.apple.com/ca/album/_/1634706525?i=1634706736",
        // youtubeURL: "https://www.youtube.com/watch?v=clt-He56mOM",
        // facebookPixel: "530282730968435",
        trackData,
      };
      setSong(song);
      setTrack(trackData.albums);
    }
  }, [trackData]);

  console.log("Artist data loaded 🎉", artist);
  console.log("Track data loaded 🎉", trackData);
  console.log("Song data loaded 🎉", song);

  return (
    <Layout song={artist} artwork={artist.artistData.images[0].url} showGrain={grain}>
      {/* <SEO title={`${artist.title}${artist.featuredArtist ? ` ft. ${artist.featuredArtist}` : ""}`} /> */}
      <Container className="release-landing-page">
        {/* <Link to={"#" || "/" + artist.artistUsername} className="logo" style={{ transform: `scale(${logoScale})` }}>
          <TruevinedSVGLogo color={"var(--logoColor)"} width={164} alt="Truevined logo" />
        </Link> */}
        {/* <div className="artwork_bg" style={{ backgroundImage: `url(${artwork})` }} /> */}
        <div className="outer-container">
          <div className={`inner-container`}>
            <div
              className="FullColorVersion2022"
              style={{
                width: windowSize.width,
                width: "100svw",
                width: "100vw",
                // height: "4657px",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                className="Background"
                style={{
                  position: "fixed",
                  inset: "0",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "var(--backgroundColor)",
                }}
              />
              <Box
                className="Clouds"
                aria-label="Clouds background"
                sx={{
                  background: `url(${clouds}) no-repeat center center fixed`,
                  backgroundSize: "cover",
                  position: "fixed",
                  inset: "0",
                  width: "100%",
                  height: "auto",
                  mixBlendMode: "screen",
                  userSelect: "none",
                  pointerEvents: "none",
                  display: "none", // hide for now
                }}
              />
              <ArtistNav artist={artist.artistData.name} />
              <HeroSection artistData={artist.artistData} song={song} />
              <MusicSection artistData={artist.artistData} song={song} />
              <VideoSection artistData={artist.artistData} song={song} />
              <GallerySection artistData={artist.artistData} song={song} />
              <ContactSection artistData={artist.artistData} song={song} />
            </div>
          </div>
        </div>
        {/* <div className="artwork_bg bottom outer">
          <div className="artwork_bg bottom" style={{ backgroundImage: `url(${artwork})` }} />
        </div> */}
      </Container>
    </Layout>
  );
}

export default ArtistTemplate;
