import { useEffect, useState } from "react";

// begin hook
function useGetNewestTrack(props, options, dependencies = []) {
  const [artistData, setArtistData] = useState(null);
  const { artistName } = props;

  async function fetchSpotifyNewestTrack(artistId) {
    if (typeof window !== "undefined") {
      const resp = await fetch(`/.netlify/functions/SpotifyGetNewestRelease`, {
        method: "POST",
        body: JSON.stringify({ artist: artistId }),
      });

      const text = await resp.text();
      return JSON.parse(text);
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (artistName) {
        let data = fetchSpotifyNewestTrack(artistName)
          .then((data) => {
            setArtistData(data);
          })
          .catch((err) => {
            console.log("err:", err);
          });
      }
    }
  }, [...dependencies]);

  if (!artistName) {
    return null;
  }

  return artistData;
}

export default useGetNewestTrack;
