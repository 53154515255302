import { useEffect, useState } from "react";

// ... (other imports)

function useSearchYouTube(props, options, dependencies = []) {
  const [youtubeData, setYoutubeData] = useState(null);
  const { artistName, songName } = props;

  async function fetchYouTubeData(artist, track) {
    if (typeof window !== "undefined") {
      const resp = await fetch(`/.netlify/functions/GetYoutubeVideo`, {
        method: "POST",
        body: JSON.stringify({ artist, trackName: track }),
      });

      const text = await resp.text();
      return JSON.parse(text);
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined" && artistName && songName) {
      fetchYouTubeData(artistName, songName)
        .then((data) => {
          setYoutubeData(data);
        })
        .catch((err) => {
          console.log("err:", err);
        });
    }
  }, [artistName, songName]);

  return youtubeData;
}

export default useSearchYouTube;
