import React, { useEffect } from "react";

const WaveFormAnimationBase = ({ numBars }) => {
  useEffect(() => {
    const bars = document.querySelectorAll(".bar");

    bars.forEach((bar) => {
      // Random move
      const animationDuration = `${Math.random() * (0.7 - 0.2) + 0.2}s`;
      bar.style.animationDuration = animationDuration;
    });
  }, []);

  return (
    <div className="sound-wave">
      {[...Array(numBars || 17)].map((_, index) => (
        <div key={index} className="bar"></div>
      ))}
    </div>
  );
};

const WaveFormAnimation = ({ height, playing, numBars }) => (
  <>
    <style>
      {`
        .sound-wave {
          height: ${height}px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .bar {
          animation-name: ${playing ? "wave-lg" : "none"};
          animation-iteration-count: infinite;
          animation-timing-function: ease-in-out;
          animation-direction: alternate;
          background: var(--textColor);
          margin: 0 1.5px;
          height: ${0.2 * height}px;
          width: 1px; 
        }

        .bar:nth-child(-n + 7),
        .bar:nth-last-child(-n + 7) {
          animation-name: ${playing ? "wave-md" : "none"};
        }

        .bar:nth-child(-n + 3),
        .bar:nth-last-child(-n + 3) {
          animation-name: ${playing ? "wave-sm" : "none"};
        }
      `}

      {`
        @keyframes wave-sm {
          0% {
            opacity: 0.35;
            height: ${0.2 * height}px;
          }
          100% {
            opacity: 1;
            height: ${height / 2}px;
          }
        }

        @keyframes wave-md {
          0% {
            opacity: 0.35;
            height: ${0.3 * height}px;
          }
          100% {
            opacity: 1;
            height: ${height}px;
          }
        }

        @keyframes wave-lg {
          0% {
            opacity: 0.35;
            height: ${0.3 * height}px;
          }
          100% {
            opacity: 1;
            height: ${height + 0.2 * height}px;
          }
        }
      `}
    </style>
    <WaveFormAnimationBase numBars={numBars} />
  </>
);

export default WaveFormAnimation;
