import React from "react";

export function ContactSection() {
  return (
    <div
      className="ContactSection"
      style={{
        position: "relative",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: 34,
        display: "inline-flex",
      }}
    >
      <div
        className="ContactMe"
        style={{
          color: "var(--textColor)",
          fontSize: 53.46,
          fontFamily: "Hanson",
          fontWeight: "700",
          wordWrap: "break-word",
        }}
      >
        CONTACT ME
      </div>
      <div
        className="LetSTalk"
        style={{
          color: "#FECF3B",
          fontSize: 23.39,
          fontFamily: "Hanson",
          fontWeight: "700",
          wordWrap: "break-word",
        }}
      >
        Let’s talk
      </div>
      <div
        className="ContactForm"
        style={{
          left: 0,
          top: 209.65,
          position: "absolute",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: 10,
          display: "flex",
        }}
      >
        <div
          className="Group1"
          style={{
            width: 478.61,
            height: 65.15,
            position: "relative",
          }}
        >
          <div
            className="Rectangle38"
            style={{
              width: 478.61,
              height: 65.15,
              left: 0,
              top: 0,
              position: "absolute",
              background: "var(--textColor)",
              borderRadius: 12.53,
            }}
          />
          <div
            className="WhatSYourName"
            style={{
              left: 20.88,
              top: 24.22,
              position: "absolute",
              color: "rgba(0, 0, 0, 0.30)",
              fontSize: 20.05,
              fontFamily: "Poppins",
              fontWeight: "700",
              lineHeight: 17.54,
              wordWrap: "break-word",
            }}
          >
            What’s your name?
          </div>
        </div>
        <div
          className="Group5"
          style={{
            width: 983.11,
            height: 65.15,
            left: 0,
            top: 450.21,
            position: "absolute",
          }}
        >
          <div
            className="Rectangle41"
            style={{
              width: 983.11,
              height: 65.15,
              left: 0,
              top: 0,
              position: "absolute",
              background: "#FECF3B",
              borderRadius: 12.53,
            }}
          />
          <div
            className="SendMessage"
            style={{
              left: 374.2,
              top: 26.73,
              position: "absolute",
              color: "black",
              fontSize: 23.39,
              fontFamily: "Hanson",
              fontWeight: "700",
              lineHeight: 17.54,
              wordWrap: "break-word",
            }}
          >
            SEND MESSAGE
          </div>
        </div>
        <div
          className="Group3"
          style={{
            width: 983.11,
            height: 65.15,
            left: 0,
            top: 96.06,
            position: "absolute",
          }}
        >
          <div
            className="Rectangle39"
            style={{
              width: 983.11,
              height: 65.15,
              left: 0,
              top: 0,
              position: "absolute",
              background: "var(--textColor)",
              borderRadius: 12.53,
            }}
          />
          <div
            className="WhatAreYouContactingMeAbout"
            style={{
              left: 20.88,
              top: 24.22,
              position: "absolute",
              color: "rgba(0, 0, 0, 0.30)",
              fontSize: 20.05,
              fontFamily: "Poppins",
              fontWeight: "700",
              lineHeight: 17.54,
              wordWrap: "break-word",
            }}
          >
            What are you contacting me about?
          </div>
        </div>
        <div
          className="Group4"
          style={{
            width: 983.11,
            height: 227.19,
            left: 0,
            top: 192.11,
            position: "absolute",
          }}
        >
          <div
            className="Rectangle40"
            style={{
              width: 983.11,
              height: 227.19,
              left: 0,
              top: 0,
              position: "absolute",
              background: "var(--textColor)",
              borderRadius: 12.53,
            }}
          />
          <div
            className="YourMessage"
            style={{
              left: 20.88,
              top: 23.39,
              position: "absolute",
              color: "rgba(0, 0, 0, 0.30)",
              fontSize: 20.05,
              fontFamily: "Poppins",
              fontWeight: "700",
              lineHeight: 17.54,
              wordWrap: "break-word",
            }}
          >
            Your message
          </div>
        </div>
        <div
          className="Group2"
          style={{
            width: 478.61,
            height: 65.15,
            left: 504.5,
            top: -0,
            position: "absolute",
          }}
        >
          <div
            className="Rectangle42"
            style={{
              width: 478.61,
              height: 65.15,
              left: 0,
              top: 0,
              position: "absolute",
              background: "var(--textColor)",
              borderRadius: 12.53,
            }}
          />
          <div
            className="EmailAddress"
            style={{
              left: 25.06,
              top: 24.22,
              position: "absolute",
              color: "rgba(0, 0, 0, 0.30)",
              fontSize: 20.05,
              fontFamily: "Poppins",
              fontWeight: "700",
              lineHeight: 17.54,
              wordWrap: "break-word",
            }}
          >
            Email address
          </div>
        </div>
      </div>
      <div
        className="Line5"
        style={{
          width: 785.98,
          height: 0,
          left: 197.12,
          top: 102.74,
          position: "absolute",
          border: "0.42px #FECF3B solid",
        }}
      ></div>
    </div>
  );
}
