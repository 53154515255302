import { Box, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "gatsby";
import React, { useState } from "react";
import { removeHyphens } from "../../_helpers";

export function ArtistNav(props) {
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuClick = () => {
    setShowMenu(!showMenu);
  };

  const linkStyle = {
    fontSize: "1.2rem",
    color: "var(--logoColor)",
    textDecoration: "none",
    "&:hover": { color: "var(--logoColor)" },
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: { xs: "9.8rem", md: "6.3rem" },
        padding: "1.42rem 2.77rem",
        left: 0,
        top: 0,
        position: "fixed",
        justifyContent: { xs: "flex-end", md: "space-between" },
        alignItems: "center",
        gap: "2.67rem",
        display: "flex",
        zIndex: 9999,
      }}
      className="Navigation"
    >
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "0.48rem",
        }}
        className="LogoWrapper"
      >
        <Typography
          className={`artist-name ${removeHyphens(props.artist)}`}
          sx={{
            color: "var(--logoColor)",
            fontSize: "2.5rem",
            fontFamily: "Covered By Your Grace",
            fontWeight: "400",
            overflowWrap: "break-word",
          }}
        >
          {props.artist}
        </Typography>
      </Box>

      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          height: "1.11rem",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "3rem",
          color: "var(--textColor)",
          fontSize: ".91rem",
          letterSpacing: "0.035rem",
          fontStyle: "normal",
          fontFamily: "Poppins",
          fontWeight: "400",
          overflowWrap: "break-word",
        }}
        className="HeaderNav"
      >
        <Link to="#" className="HeaderNav-item Home" component={RouterLink} sx={linkStyle}>
          HOME
        </Link>
        <Link to="#" className="HeaderNav-item Music" component={RouterLink} sx={linkStyle}>
          MUSIC
        </Link>
        <Link to="#" className="HeaderNav-item Shop" component={RouterLink} sx={linkStyle}>
          SHOP
        </Link>
        <Link to="#" className="HeaderNav-item Tours" component={RouterLink} sx={linkStyle}>
          TOURS
        </Link>
        <Link to="#" className="HeaderNav-item Contact" component={RouterLink} sx={linkStyle}>
          CONTACT
        </Link>
      </Box>

      <Box
        sx={{
          width: { xs: "45px", md: "2.8125rem" },
          height: { xs: "45px", md: "2.8125rem" },
          position: "relative",
          cursor: "pointer",
          zIndex: "2",
          transition: "transform .3s ease-in-out",
          "::before": {
            content: '""',
            position: "absolute",
            width: "100%",
            height: { xs: "2px", md: "0.125rem" },
            background: "var(--logoColor)",
            transform: { xs: "translate(0%, 17px) scaleX(100%)", md: "translate(0%, 1.0625rem) scaleX(100%)" },
            transition: "transform 0.3s ease-in-out",
          },
          "::after": {
            content: '""',
            position: "absolute",
            width: "100%",
            height: { xs: "2px", md: "0.125rem" },
            background: "var(--logoColor)",
            transform: { xs: "translate(15%, 26px) scaleX(70%)", md: "translate(.4rem, 1.75rem) scaleX(70%)" },
            transition: "transform 0.3s ease-in-out",
          },
          "&:hover": {
            "::before": {
              transform: { xs: "translate(0%, 15px) scaleX(100%)", md: "translate(0%, 0.9375rem) scaleX(100%)" },
            },
            "::after": {
              transform: { xs: "translate(15%, 28px) scaleX(70%)", md: "translate(.4rem, 1.85rem) scaleX(70%)" },
            },
          },
          "&.active": {
            transform: "rotate(-90deg)",
            "::before": {
              transform: {
                xs: "translate(0%, 22px) rotate(45deg) scale(100%)",
                md: "translate(0%, 1.375rem) rotate(45deg) scale(100%)",
              },
            },
            "::after": {
              transform: {
                xs: "translate(0%, 22px) rotate(-45deg) scale(100%)",
                md: "translate(0%, 1.375rem) rotate(-45deg) scale(100%)",
              },
            },
          },
        }}
        className={"MenuIcon" + (showMenu ? " active" : "")}
        onClick={handleMenuClick}
      ></Box>

      <Box
        sx={{
          position: "fixed",
          inset: 0,
          display: { xs: showMenu ? "flex" : "none" }, // Show on xs screens when menu is toggled
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "var(--color5)", // Customize as needed
          color: "var(--logoColor)",
          fontWeight: "400",
          fontFamily: "'Monument Regular'",
          fontSize: "40px",
          flexWrap: "wrap",
          gap: "1rem",
          padding: "3rem 7rem",
        }}
        className="FullscreenMenu"
      >
        <Box sx={{ padding: "0.5rem", cursor: "pointer" }} className="FullscreenMenuItem">
          HOME
        </Box>
        <Box sx={{ padding: "0.5rem", cursor: "pointer" }} className="FullscreenMenuItem">
          MUSIC
        </Box>
        <Box sx={{ padding: "0.5rem", cursor: "pointer" }} className="FullscreenMenuItem">
          SHOP
        </Box>
        <Box sx={{ padding: "0.5rem", cursor: "pointer" }} className="FullscreenMenuItem">
          TOURS
        </Box>
        <Box sx={{ padding: "0.5rem", cursor: "pointer" }} className="FullscreenMenuItem">
          CONTACT
        </Box>
      </Box>
    </Box>
  );
}
