import { Box } from "@mui/material";
import React from "react";
import useWindowSize from "../../../hooks/useWindowSize";
import { BREAKPOINT, generateSummary, safeString } from "../../_helpers";

export function HeroSection(props) {
  const windowSize = useWindowSize();
  const { artistData, song } = props;
  const artistName = artistData.name;
  const artistImage = artistData.images[0].url;
  const trackData = song?.trackData;
  const [showHalfBioMobile, setShowHalfBioMobile] = React.useState(false);

  let artistBio = "";

  if (trackData && artistData) {
    artistBio = generateSummary(
      song?.trackData,
      artistData,
      song,
      true,
      "var(--color1)",
      windowSize.width < BREAKPOINT && !showHalfBioMobile
    );
  }

  return (
    <Box
      sx={{
        mt: { xs: "56px", md: "9.1rem" },
        position: "relative",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "3.0625rem",
        display: "inline-flex",
        width: "100%",
        height: "100%",
      }}
      className="HeroSection"
    >
      <Box
        className="StoryIcon"
        sx={{
          width: { xs: "28rem", md: "12.25rem" },
          height: { xs: "28rem", md: "12.25rem" },
          position: "relative",
          mt: "1rem",
          mb: { xs: "7rem", md: "2rem" },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          component="div"
          aria-label={`${artistName} profile picture`}
          sx={{
            width: { xs: "26.3rem", md: "11rem" },
            height: { xs: "26.3rem", md: "11rem" },
            borderRadius: "28rem",
            backgroundImage: `url(${artistImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className="Image1"
        />
        <Box
          className="Ellipse10"
          sx={{
            width: { xs: "29rem", md: "12.25rem" },
            height: { xs: "29rem", md: "12.25rem" },
            position: "absolute",
            borderRadius: "28rem",
            border: { xs: "0.65rem var(--color1) solid", md: "0.3125rem var(--color1) solid" },
          }}
        />
      </Box>

      <Box
        className={safeString(artistName)}
        sx={{
          color: "var(--logoColor)",
          fontFamily: "Bellefair",
          fontSize: { xs: "5.6rem", md: "7rem", lg: "8.75rem" },
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "normal",
          letterSpacing: "-0.15rem",
          textTransform: "uppercase",
        }}
      >
        {artistName}
      </Box>

      {!trackData || !artistData || !song ? (
        <Box
          sx={{
            width: "77%",
            borderRadius: "1rem",
            display: "flex",
            flexDirection: "column",
            gap: "1.16rem",
          }}
        >
          <Box sx={{ width: "100%", height: "20px", backgroundColor: "#ffffff10", borderRadius: "0.5rem" }} />
          <Box sx={{ width: "100%", height: "20px", backgroundColor: "#ffffff10", borderRadius: "0.5rem" }} />
          <Box sx={{ width: "100%", height: "20px", backgroundColor: "#ffffff10", borderRadius: "0.5rem" }} />
        </Box>
      ) : (
        <Box
          className="IntroBio"
          sx={{
            width: "100%",
            maxWidth: "60rem",
            padding: { xs: "0 5vw", md: "0" },
            textAlign: "center",
            color: "var(--logoColor)",
            fontSize: { xs: "2.1rem", md: "1.2rem" },
            fontFamily: "Poppins",
            fontWeight: "400",
            lineHeight: "1.9",
            overflowWrap: "break-word",
            animation: "fade-in 1s ease",
            "& > span": {
              minHeight: { xs: showHalfBioMobile ? "238px" : "147px", md: "auto" },
              transition: "min-height 0.5s ease",
            },
          }}
        >
          {windowSize.width < BREAKPOINT && artistBio.length > 200 ? artistBio.slice(0, 200) + "..." : artistBio}
          {windowSize.width < BREAKPOINT && (
            <Box
              className="More-btn"
              onClick={() => {
                setShowHalfBioMobile(!showHalfBioMobile);
              }}
              sx={{
                display: "inline-flex",
                gap: ".65rem",
                position: "relative",
                color: "var(--logoColor)",
                fontSize: "inherit",
                fontFamily: "Poppins",
                fontWeight: "700",
                lineHeight: "inherit",
                marginLeft: "1rem",
                position: "relative",
                cursor: "pointer",
                "&:before": {
                  content: '""',
                  position: "absolute",
                  bottom: "3px",
                  left: "0",
                  width: showHalfBioMobile ? "92%" : "82%",
                  height: "2px",
                  backgroundColor: "var(--color1)",
                },
              }}
            >
              {showHalfBioMobile ? "Collapse Bio " : "More "}
              <span className="MoreArrow">
                <svg
                  width="0.625rem"
                  height="0.8125rem"
                  viewBox="0 0 10 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.6598 4.6471C10.0543 5.54694 10.0543 7.58645 8.6598 8.48628L3.53363 11.7941C2.01344 12.775 0.0104383 11.6837 0.0104384 9.87447L0.0104387 3.25891C0.0104388 1.44971 2.01344 0.358382 3.53364 1.33932L8.6598 4.6471Z"
                    fill="var(--logoColor)"
                  />
                </svg>
              </span>
            </Box>
          )}
        </Box>
      )}

      <Box
        className="Scroll"
        sx={{
          color: "var(--logoColor)",
          fontSize: "25px",
          fontFamily: "Bellefair",
          fontWeight: "400",
          textTransform: "uppercase",
          mt: { xs: "17rem", md: "7rem" },
          animation: "float 2.8s cubic-bezier(0.07, 0.41, 0.55, 0.98) infinite",
        }}
      >
        SCROLL
      </Box>
    </Box>
  );
}
